import { useState } from "react";
import HalfWavesImg from "../../assets/02/WaveLines.png";
import MetaverseImg from "../../assets/02/TenticleIcon.svg";
import CEOImg from "../../assets/02/CEO.png";
import PresidentImg from "../../assets/02/President.png";
import GameIcon from "../../assets/02/GameIcon.svg";
import * as sc from "./styledComponents";
import { Button, HalfWavesImage, PageHeading } from "../_Global/globalComponents";
import { useNavigate } from "react-router-dom";
import { GAMES_PATH, PURPOSE_PATH } from "../../constants/routeConstants";
import { FindOutSection, Footer } from "../_Global/Global";

const Founders = () =>  {
  return <sc.FoundersWrapper>
    <sc.TeamsCard>
      <sc.TeamsHeading>OK2 GAMING</sc.TeamsHeading>
      <sc.FoundersText>Founders</sc.FoundersText>
    </sc.TeamsCard>
    <sc.FounderCard>
      <sc.FounderImg src={PresidentImg} />
      <sc.FounderDesignation>President</sc.FounderDesignation>
      <sc.FounderName>Dr. Sambasiva R Korrapolu</sc.FounderName>
      <sc.AboutFounder>
        Proud to introduce a passionate yoga teacher for the past 20 years.
        Ph.D. holder at the age of 78 in Kundalini Yoga, 
        solved a variety of health problems with Yoga techniques
        benefitting 1000s of yoga sadhaks.
      </sc.AboutFounder>
    </sc.FounderCard>
    <sc.CEOCard>
      <sc.FounderImg src={CEOImg} />
      <sc.FounderDesignation>CEO</sc.FounderDesignation>
      <sc.FounderName>Shankar Korrapolu</sc.FounderName>
      <sc.AboutFounder>
        Survived stage 4 cancer and helping dozens of cancer patients
        with Fasting, Food as a Medicine and Yoga therapies. Working with
        the Biden’s administration Moonshot project to reduce cancer by
        50% in the next 25 years.
      </sc.AboutFounder>
    </sc.CEOCard>
  </sc.FoundersWrapper>
}

const Purpose = () => {
  const navigate = useNavigate();
  return (
    <sc.PurposeCard>
      <sc.PurposeWrapper>
        <sc.PurposeTextWrapper>
          <sc.PurposeHeading>OUR PURPOSE</sc.PurposeHeading>
          <sc.PurposeText>
            We want to create the future of sustainable health with gaming, unmissable entertainment,
            anywhere, with billions of smiles.
          </sc.PurposeText>
        </sc.PurposeTextWrapper>
        <sc.GameIconImg src={GameIcon} />
      </sc.PurposeWrapper>
      <sc.ReadMoreDiv>
        <Button onClick={() => { window.scrollTo(0, 0); navigate(PURPOSE_PATH) }}>READ MORE</Button>
      </sc.ReadMoreDiv>
    </sc.PurposeCard>
  );
};

const About = () => {
  const [animationStatus, setAnimationStatus] = useState("Play");
 
  window.onpopstate = function (event) {
    setAnimationStatus("PlayReverse");
    setTimeout(() => {
      setAnimationStatus("Stop");
    }, 1000);
  };

  return (
    <sc.Wrapper status={animationStatus}>
      <HalfWavesImage src={HalfWavesImg} status={animationStatus} />
      <sc.MetaverseWrapper>
        <PageHeading>OK2 METAVERSE</PageHeading>
        <sc.AboutCard>
          <sc.AboutTextWrapper>
            <sc.MetaverseImageWrapper>
              <sc.AboutHeading>ABOUT</sc.AboutHeading>
            </sc.MetaverseImageWrapper>
            <sc.AboutText>
              OK2 is specializing in building mobile games faster, cheaper and better.
            </sc.AboutText>
          </sc.AboutTextWrapper>
          <sc.MetaverseImage src={MetaverseImg} />
        </sc.AboutCard>
        <Founders />
        <Purpose />
        <FindOutSection button="Our Games" path={GAMES_PATH} title="New Games!"/>
        <Footer />
      </sc.MetaverseWrapper>
    </sc.Wrapper>
  );
};
export default About;
