import * as sc from "./styledComponents";
import Sketch from "react-p5";
import p5Types from "p5";
import BackgroundImageSrc from "../../assets/01/StarsBGCurved.png";
import Ok2Logo from "../../assets/01/OK2.png"
import SudokuImage from "../../assets/01/Sudoku.svg";
import SudokuCardSrc from "../../assets/01/SudokuInterface-0.png";
import AboutImage from "../../assets/01/About.png";
import GamesImage from "../../assets/01/Games.png";
import AboutPressedImage from "../../assets/01/About-PressedState.png";
import GamesPressedImage from "../../assets/01/Games-PressedState.png";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ABOUT_PATH, GAMES_PATH } from "../../constants/routeConstants";

const Button = (props: any) => {
  const [hover, setHover] = useState(false)
  return (
    <sc.ImgButton src={hover ? props.pressed : props.image} alt="" onClick={props.onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} />
  )
}

const Terrain: FC = (_props) => {
  const navigate = useNavigate();
  const [animationStatus, setAnimationStatus] = useState("Play");
  window.onpopstate = function (_event) {
    window.location.reload();
    setAnimationStatus("PlayReverse");
    setTimeout(() => {
      setAnimationStatus("Stop");
    }, 1000);
  };
  var terrainValues: any = [];
  var mult = 100;
  var xoff = 0;
  var yoff = 0;
  var inc = 0.08;
  var max_x = window.outerWidth*1.2;
  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.createCanvas(max_x, window.innerHeight , p5.WEBGL).parent(canvasParentRef);
    p5.angleMode(p5.DEGREES);
    p5.noiseDetail(2, 0.6);
    p5.colorMode(p5.RGB);
    for (var y: number = 0; y < 50; y++) {
      terrainValues.push([]);
      xoff = 0;
      for (var x: number = 0; x < max_x; x++) {
        terrainValues[y][x] = p5.map(p5.noise(xoff, yoff), 0, 1, -mult, mult);
        xoff = xoff + inc;
      }
      yoff = yoff + inc;
    }
    p5.frameRate(20);
  };

  const draw = (p5: p5Types) => {
    p5.noFill();
    p5.stroke('rgba(255,179,0,0.88)');
    p5.background(p5.color(17, 42, 52, 1));
    p5.rotateX(25);
    p5.translate(-(p5.width * 2 / 3) - p5.frameCount, -p5.height / 2);
    for (var y = 0; y < 50; y++) {
      if (y === 18) {
        p5.strokeWeight(6);
      } else {
        p5.strokeWeight(2);
      }
      p5.beginShape();
      for (var x = 0; x < max_x/8; x++) {
        p5.vertex((x * 12) + p5.frameCount, y * 15, terrainValues[y][(x + p5.frameCount) % max_x]);
      }
      p5.endShape();
    }
  };

  const openAbout = () => {
    navigate(ABOUT_PATH)
  }
  const openGames = () => {
    navigate(GAMES_PATH)
  }
  return <sc.Container status={animationStatus} >
    <sc.BackgroundImage src={BackgroundImageSrc}/>
    <sc.Logo src={Ok2Logo} />
<sc.ContentDiv>
      <sc.WavesDiv>
        <sc.SudokuWrapper>
            <sc.SudokuImgDiv>
              <sc.SudokuText>SUDOKU</sc.SudokuText>
              <sc.SudokuImg src={SudokuImage} />
            </sc.SudokuImgDiv>
            <sc.SudokuCard>
              <sc.SudokuCardImg src={SudokuCardSrc} />
              <sc.SudokuCardText>Cross the charm with this epic memory based number puzzle game that lets you unlock, learn, and battle with friends making it easier for anyone to jump on.</sc.SudokuCardText>
            </sc.SudokuCard>
        </sc.SudokuWrapper>
      </sc.WavesDiv>
      <sc.ButtonsDiv>
        <Button onClick={openAbout} image={AboutImage} pressed={AboutPressedImage} />
        <Button onClick={openGames} image={GamesImage} pressed={GamesPressedImage} />
      </sc.ButtonsDiv>
</sc.ContentDiv>
    <sc.SketchDiv>
      <Sketch setup={setup} draw={draw} />
    </sc.SketchDiv>
  </sc.Container>;
}
export default Terrain;

