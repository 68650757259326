import * as gc from "./globalComponents";
import { addEmail } from "../../firebase";
import NewgamesImgSrc from "../../assets/02/CardsIcon.svg";
import CompanySrc from "../../assets/02/Company.svg";
import ContactSrc from "../../assets/02/Contact.svg";
import CareerSrc from "../../assets/02/Career.svg";
import FinishImgSrc from "../../assets/04/EndLine.png";
import MusicIconSrc from "../../assets/01/Volume.png";
import MusicPressedSrc from "../../assets/01/Volume-PressedState.png";
import MusicOffSrc from "../../assets/01/Mute.png";
import MusicOffPressedSrc from "../../assets/01/Mute-PressedState.png";
import AudioSrc from "../../assets/sounds/BG.mp3";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

export const Footer = () => {
  return <gc.FooterWrapper>
    <gc.FooterIconWrapper>
      <gc.LinkWrapper href="/about">
        <gc.FooterIconTextWrapper>
          <gc.FooterIcon src={CompanySrc} />
          <gc.FooterIconText>Company</gc.FooterIconText>
        </gc.FooterIconTextWrapper>
      </gc.LinkWrapper>
      <gc.FooterIconTextWrapper>
        <gc.FooterIcon src={CareerSrc} />
        <gc.FooterIconText>Career</gc.FooterIconText>
      </gc.FooterIconTextWrapper>
      <gc.FooterIconTextWrapper>
        <gc.FooterIcon src={ContactSrc} />
        <gc.FooterIconText>Contact</gc.FooterIconText>
      </gc.FooterIconTextWrapper>
    </gc.FooterIconWrapper>
    <gc.CopyrightWrapper>
      <gc.CopyrightText>
        Copyright © 2022. All Rights Reserved By OK2
      </gc.CopyrightText>
      <gc.Terms href={"/terms"}>Terms</gc.Terms>
    </gc.CopyrightWrapper>
  </gc.FooterWrapper>
}

export const FindOutSection = (props: any) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const subscribe = async () => {
    if (email.match(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    )) {
      setEmailError("");
      setLoading(true);
      var ref = await addEmail(email);
      if (ref.id) {
        setEmailSuccess("Subscribed Successfully");
      } else {
        setEmailError("something went wrong please try again");
      }
      setLoading(false);
    }
    else {
      setEmailError("Invalid Email");
    }
  }
  return <gc.FindOutSectionWrapper>
    <gc.FindOutImg src={NewgamesImgSrc} />
    <gc.FindOutTextWrapper>
      <gc.FindOutText>
        Find Out About {props.title}
      </gc.FindOutText>
      <gc.FindOutButtonDiv>
        <gc.Button onClick={() => { window.scrollTo(0, 0); navigate(props.path) }}>
          {props.button}
        </gc.Button>
      </gc.FindOutButtonDiv>
      {emailSuccess && <gc.SubscribedAlert>
        You are subscribed for updates
      </gc.SubscribedAlert>}
      {!emailSuccess &&
        <gc.SubscribeWrapper>
          <gc.EmailInput placeholder="Type Email" onInput={(e) => setEmail((e.target as HTMLInputElement).value)} value={email} />
          <gc.SubscribeButton onClick={!loading ? subscribe : () => { console.log(loading) }}>{!loading ? 'SUBSCRIBE' : 'Loading'}</gc.SubscribeButton>
        </gc.SubscribeWrapper>
      }
      {emailError && <gc.ErrorText>{emailError}</gc.ErrorText>}
    </gc.FindOutTextWrapper>
  </gc.FindOutSectionWrapper>
}

export const FinishImage = () => {
  return <gc.CenterDiv><gc.FinishImg src={FinishImgSrc} /></gc.CenterDiv>
}

export const MusicComponent = () => {
  const audioRef = useRef(new Audio(AudioSrc));
  const audio = audioRef.current;
  audio.loop = true;

  const [playing, setPlaying] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio])

  const getIcon = () => {
    if (!playing) {
      return hover ? MusicOffPressedSrc : MusicOffSrc;
    }
    else {
      return hover ? MusicPressedSrc : MusicIconSrc;
    }
  }
  return <gc.MusicIcon onClick={() => { setPlaying(!playing) }} style={{ zIndex: 5 }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
    <img src={getIcon()} alt="" width={50} />
  </gc.MusicIcon>
}