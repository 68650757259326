import styled from "@emotion/styled";
import * as gc from "../_Global/globalComponents";

type AnimationProps = {
  status: string;
};


export const Wrapper = styled.div`
  background-color: #112a34;
  animation: ${(props: AnimationProps) =>
      props.status === "Play" ? gc.SlideDown : "none"}
    1s linear 1;
  animation-timing-function: ease-out;
  position: relative;
`;

export const GamesWrapper = styled.div`
  width: 80vw;
  left:10vw;
  position: absolute;
  padding-top: 5vh;
  top:20vh;
  background: transparent
    linear-gradient(181deg, #f8f32b 0%, #dad62f 18%, #f6f47f 54%, #fffd98 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
  @media screen and (max-width: 1080px) {
   top:10vh;
   padding-top: 2vh;
  }
`;

export const PaddingWrapper = styled.div`
  padding-left: 5em;
  padding-right: 2em;
  @media screen and (max-width: 1080px) {
    padding:0em;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 1080px) {
    padding:0em 1em;
    flex-direction: column-reverse;
   }
`;

export const ContentDiv  = styled.div`
  margin-bottom:12vh;
  margin-right:3vw;
  @media screen and (max-width: 768px) {
    margin-bottom:4vh;
  }
  @media screen and (max-width: 1080px) {
    margin-bottom:2vh;
  }
`;

export const GameName = styled.p`
  font: normal normal 600 2em/1.5em Josefin Sans;
  letter-spacing: 0px;
  color: #0e1428;
  opacity: 1;
  margin:1.5vh 0vh;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 22px/26px Josefin Sans;
    padding: 0em;
  }
`;

export const RatingCard = styled.div`
  width:26vw;
  padding: 2em;
  background: #ff8811 0% 0% no-repeat padding-box;
  border-radius: 0px 22px;
  opacity: 1;
  border: none;
  @media screen and (max-width: 1080px) {
    width: 90%;
    margin: 0 auto;
    padding: 1em;
    border-radius: 0px 18px;
   }
`;

export const GameText = styled.p`
  font: normal normal normal 24px/28px Josefin Sans;
  color: #fff4f0;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal 500 18px/18px Josefin Sans;
  }
`;

export const FeaturesText = styled.p`
  font: normal normal normal 20px/24px Josefin Sans;
  vertical-align: start;
  color: #fff4f0;
  opacity: 1;
  margin-bottom:0.5em;
  @media screen and (max-width: 1080px) {
    font: normal normal 500 16px/18px Josefin Sans;
  }
`;

export const DownloadWrapper = styled.div`
  margin:3vh auto;
`;

export const DownloadText = styled.p`
  font: normal normal 600 42px/58px Josefin Sans;
  letter-spacing: 0px;
  color: #0e1428;
  opacity: 1;
  margin:0.5em auto;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 24px/26px Josefin Sans;
  }
`;

export const StoresWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const StoreImg = styled.img`
  height:5em;
  margin-right:1em;
  @media screen and (max-width: 1080px) {
    height:2.5em;
    margin-right:0.5em;
  }
`;

export const SecOneImageDiv = styled.div`
  height:75vh;
  width:70%;
  max-width:32vw;
  position:relative;
  @media screen and (max-width: 1080px) {
    display:none;
  }
`;

// [top,r/l,mobile-top,mobiler/l,change,time]
export const SudokuImg1 = gc.sudokuImgRight([-10,0,-5,-1,1,10]);
export const SudokuImg2 = gc.sudokuImgLeft([5,-2,5,0,0,10]);
export const SudokuImg3 = gc.sudokuImgRight([30,-1,18,-3,-1.5,8]);