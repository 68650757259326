import { Route } from "react-router-dom";
import { About, Games, Home, Terrain, Purpose,PageNotFound } from "../components";
import Battle from "../components/Battle";
import Terms from "../components/Terms";

import {
  GAMES_PATH,
  HOME_PAGE_PATH,
  ABOUT_PATH,
  TERRAIN_PATH,
  PURPOSE_PATH,
  TERMS_PATH,
  BATTLE_PATH
} from "../constants/routeConstants";

const routes = [
  <Route key={HOME_PAGE_PATH} path={HOME_PAGE_PATH} element={<Home />} />,
  <Route key={GAMES_PATH} path={GAMES_PATH} element={<Games />} />,
  <Route key={ABOUT_PATH} path={ABOUT_PATH} element={<About />} />,
  <Route key={TERRAIN_PATH} path={TERRAIN_PATH} element={<Terrain />} />,
  <Route key={PURPOSE_PATH} path={PURPOSE_PATH} element={<Purpose />} />,
  <Route key={TERMS_PATH} path={TERMS_PATH} element={<Terms />} />,
  <Route key={BATTLE_PATH} path={BATTLE_PATH} element={<Battle />} />,
  <Route key="NOT_FOUND" path="*" element={<PageNotFound/>} />
];

export { routes };
