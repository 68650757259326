import { keyframes } from '@emotion/react';
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { SlideDown } from "../_Global/globalComponents";
type AnimationProps = {
  status: string;
};

export const BoxAnimation = keyframes`
  0%,100% {
    transform: translate(10px,0px);
  }
  25% {
    transform: translate(20px,10px);
  }
  50% {
    transform: translate(10px,20px);
  }
  75% {
    transform: translate(0px,10px);
  }
`;

export const Wrapper = styled.div`
  background-color: #112a34;
  animation: ${(props: AnimationProps) =>
    props.status === "Play" ? SlideDown : "none"}
    1s linear 1;
  animation-timing-function: ease-out;
  position: relative;
  height: 100%;
`;

export const PurposeWrapper = styled.div`
  width: 80vw;
  left:10vw;
  position: absolute;
  padding-top: 5vh;
  top:20vh;
  background: transparent
    linear-gradient(181deg, #f8f32b 0%, #dad62f 18%, #f6f47f 54%, #fffd98 100%)
    0% 0% no-repeat padding-box;
  @media screen and (max-width: 1080px) {
    top:10vh;
    padding-top: 0vh;
    left:10vw;
  }
`;

export const PaddingWrapper = styled.div`
  padding:0 5em;
  padding-top:8vh;
  @media screen and (max-width: 1080px) {
    padding:0 1.5em;
  }
`;

export const ContentDiv = styled.div`
  display:flex;
  margin-bottom:0.5em;
`;

export const PurposeHeading = styled.p`
  font: normal normal 500 4em Josefin Sans;
  letter-spacing: 0px;
  color: #0e1428;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 1.75em Josefin Sans;
    margin: 2.5vh 0;
    margin-bottom: 1.5vh;
  }
`;

export const TextDiv = styled.div`
  width:68%;
  padding-right:2vw;
  @media screen and (max-width: 1080px) {
    width:auto;
    padding-right:0;
  }
`;

export const PurposeText = styled.p`
  text-align: left;
  font: normal normal 500 1.75em/1.5em Josefin Sans;
  letter-spacing: 0px;
  color: #4B4E2A;
  opacity: 1;
  margin: 2vh 0;
  @media screen and (max-width: 1080px) {
    font: normal normal 500 1.25em Josefin Sans;
    margin: 1vh 0;
  }
`;
export const PurposeList = styled.ul`
  margin-left: 2vw;
  list-style-type: lower-alpha;
  @media screen and (max-width: 1080px) {
    margin-left:5vw;
  }
`;

export const PurposeOuterList = styled.ul`
  list-style-type:square;
  margin-left: 2vw;
  @media screen and (max-width: 1080px) {
    margin-left:5vw;
  }
`;

export const PurposeListItemBold = styled.li`
  font: normal normal 500 1.75em/1.5em Josefin Sans;
  font-weight:bold;
  letter-spacing: 0px;
  color: #4B4E2A;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal normal 1.25em Josefin Sans;
  }
`;

export const PurposeListItem = styled.li`
  font: normal normal 500 1.75em/1.5em Josefin Sans;
  letter-spacing: 0px;
  color: #4B4E2A;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal normal 1.25em Josefin Sans;
  }
`;

export const ImagesDiv = styled.div`
  display: flex;
  width:32%;
  min-height:100%;
  flex-direction: column;
  @media screen and (max-width: 1080px) {
    display:none;
  }
`;

export const BoxImageContainer = styled.div`
  width:100%;
  height:16%;
  position:relative;
  display:flex;
  justify-content:flex-start;
  align-items:center;
`;

export const BoxImage = styled.img`
  height:8em;
  position:absolute;
  animation: ${BoxAnimation} 4s linear infinite;
  animation-delay: ${(props: {delay:string|undefined}) => props.delay}s;
`;

export const right = css`
  justify-content: flex-end !important;
`;

export const AuthorDiv = styled.div``

export const AuthorNameDiv = styled.div`
  margin-top:2vh;
  display:flex;
  align-items:center;
`;

export const AuthorName = styled.h3`
  text-align: left;
  font: normal normal 600 2.25em/1.5em Josefin Sans;
  letter-spacing: 0px;
  color: #D8315B;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 1.5em Josefin Sans;
  }
`;

export const AuthorSocialDiv = styled.div`
  margin-left:1vw;
  display:flex;
  @media screen and (max-width: 1080px) {
    margin-left:0.05vw;
  }
`;

export const Line = styled.div`
  border-left: 2px solid #DEDA2F;
  margin:0 1vw;
  height:2em;
`;

export const SocialImage = styled.img`
  margin:0 0.5vw;
  height:2em;
  @media screen and (max-width: 1080px) {
    height: 1.5em;
  }
`;

export const AuthorDetailsList = styled.ul`
  list-style-position: outside;
  margin-left: 2vw;
  @media screen and (max-width: 1080px) {
    margin-left:5vw;
  }
`;
