import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
type AnimationProps = {
  status: string;
};
export const SlideUp = keyframes`
  0% {opacity:1;}
  10% {bottom:10px;}
  100% {bottom:200px;opacity:0;}
`;

export const SlideDown = keyframes`
  0% {bottom:200px;opacity:0;}
  10% {bottom:100px;}
  100% {bottom:0px;opacity:1;}
`;
export const slideupAnimationFunction = (props: AnimationProps) => {
  if (props.status === "Play") {
    return SlideUp;
  }
  else if (props.status === "PlayReverse") {
    return SlideDown;
  }
  else return "none";
}
export const slideupAnimationTimingFunction = (props: AnimationProps) => {
  if (props.status === "Play") {
    return "ease-out";
  }
  else if (props.status === "PlayReverse") {
    return "ease-in";
  }
  else return "none";
}

export const sudokuImgLeft =(pos:any)=>styled.img`
width: 20vw;
height: 55vh; 
position: absolute;
top: ${pos[0]}vh;
left:${pos[1]}vw;
animation: ${moveRandomLeft(pos)} ${pos[5]}s linear infinite;
@media screen and (max-width: 1080px) {
  width: 40vw;
  height: 32vh;
  top: ${pos[2]}vh;
  left:${pos[3]}vw;
  animation: ${moveRandomLeftMobile([pos[2],pos[3]])} ${pos[5]}s linear infinite;
 }
`

export const sudokuImgRight =(pos:any)=>styled.img`
width: 20vw;
height: 55vh; 
position: absolute;
top: ${pos[0]}vh;
right:${pos[1]}vw;
animation: ${moveRandomRight(pos)} ${pos[5]}s linear infinite;
@media screen and (max-width: 1080px) {
  width: 40vw;
  height: 32vh;
  top: ${pos[2]}vh;
  right:${pos[3]}vw;
  animation: ${moveRandomRightMobile([pos[2],pos[3]])} ${pos[5]}s linear infinite;
 }
`

export const moveRandomLeft = (pos:any) => keyframes`
  0% {top:${pos[0]}vh;left:${pos[1]}vw;},
  25%{top:${pos[0]+1}vh;left:${pos[1]-1}vw;},
  50%{top:${pos[0]}vh;left:${pos[1]}vw;},
  75%{top:${pos[0]-1}vh;left:${pos[1]-1}vw;},
  100%{top:${pos[0]}vh;left:${pos[1]}vw;},
`;

export const moveRandomRight = (pos:any) => keyframes`
  0% {top:${pos[0]}vh;right:${pos[1]}vw;},
  25%{top:${pos[0]+pos[4]}vh;right:${pos[1]-pos[4]}vw;},
  50%{top:${pos[0]}vh;right:${pos[1]}vw;},
  75%{top:${pos[0]+pos[4]}vh;right:${pos[1]-pos[4]}vw;},
  100%{top:${pos[0]}vh;right:${pos[1]}vw;},
`;

export const moveRandomLeftMobile = (pos:any) => keyframes`
  0% {top:${pos[0]}vh;left:${pos[1]}vw;},
  25%{top:${pos[0]-1}vh;left:${pos[1]-2}vw;},
  50%{top:${pos[0]}vh;left:${pos[1]}vw;},
  75%{top:${pos[0]+1}vh;left:${pos[1]-2}vw;},
  100%{top:${pos[0]}vh;left:${pos[1]}vw;},
`;
export const moveRandomRightMobile = (pos:any) => keyframes`
  0% {top:${pos[0]}vh;right:${pos[1]}vw;},
  25%{top:${pos[0]+1}vh;right:${pos[1]+2}vw;},
  50%{top:${pos[0]}vh;right:${pos[1]}vw;},
  75%{top:${pos[0]-0.5}vh;right:${pos[1]+1.5}vw;},
  100%{top:${pos[0]}vh;right:${pos[1]}vw;},
`;

export const HalfWavesImage = styled.img`
  width: 100vw;
  height: 34vh;
  object-fit: cover; 
  position: relative;
  animation: ${(props: AnimationProps) =>
      props.status === "Play" ? SlideDown : "none"}
    1s linear 1;
  animation-timing-function: ease-out;
  @media screen and (max-width: 768px) {
    height: 17vh; // reduce to half in mobile
  }
`;

export const PageHeading = styled.p`
  margin: 5vh 0;
  margin-bottom: 8vh;
  text-align: center;
  font: normal normal 600 5em Josefin Sans;
  letter-spacing: 0px;
  color: #0e1428;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 1.8em Josefin Sans;
    margin: 2.5vh 0;
    margin-bottom: 2vh;
  }
`;

export const CenterDiv = styled.div`
  text-align: center;
`;


export const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkWrapper = styled.a`
  text-align: center;
` 

export const Button = styled.button`
  background: #1098F7 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 25px #0000002E;
  border: 0px solid #1098f7;
  border-radius: 0.5em;
  font: normal normal normal 1.5em Josefin Sans;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 0.85em 0.75em;
  padding-top: 0.9em;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  &:hover {
    background: #8B9EB7;
    box-shadow: 0px 24px 25px #0000004D;
  }
  @media screen and (max-width: 1080px) {
    margin-right:0;
    font: normal normal normal 1em Josefin Sans;
  }

`;

export const FooterWrapper = styled.div`
`;

export const FooterIconWrapper = styled.div`
  padding:2vh;
  display:flex;
  justify-content:space-between;
  align-items:center;
`;

export const FooterIconTextWrapper = styled.div`
  padding:2vh;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  @media screen and (max-width: 768px) {
    padding:1vh;
  }`;

export const FooterIconText = styled.p`
  font: normal normal 600 22px/26px Josefin Sans;
  text-decoration:underline;
  text-transform:uppercase;
  color: #4F2815;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 14px/20px Josefin Sans;
  }`;

export const FooterIcon = styled.img`
  height:4em;
  margin:1vw;
  @media screen and (max-width: 768px) {
    height:2em;
 }`;

export const CopyrightWrapper = styled.div`
  background: #32533D 0% 0% no-repeat padding-box;
  padding: 2vh 4vw;
  opacity: 0.37;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1080px) {
    padding: 1vh 5vw;
 }`;

export const CopyrightText = styled.p`
  text-align: left;
  font: normal normal normal 20px/28px Josefin Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  @media screen and (max-width: 768px) {
   font: normal normal normal 16px/18px Josefin Sans;
 }
`;

export const Terms = styled.a`
  text-align: left;
  font: normal normal normal 20px/28px Josefin Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-decoration: underline;
  @media screen and (max-width: 768px) {
    font: normal normal normal 16px/18px Josefin Sans;
  }
`
export const FindOutSectionWrapper = styled.div`
  display: flex;
  background-color: #FF8811;
  margin-top:20vh;
  height: 42vh;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    margin-top:2vh;
    height: auto;
    padding: 1em;
  }
  @media screen and (max-width: 1080px) {
    margin-top:1vh;
    height: auto;
    padding: 0.5em;
  }`;

  export const FindOutImg = styled.img`
  height: 50vh;
  max-width:30vw;
  transform:scale(1.2) translateY(-2vh) translateX(5vh);
  @media screen and (max-width: 768px) {
    width: 0vw;
    height: 0vw;
 }
 @media screen and (max-width: 1080px) {
  transform:scale(1.2) translateY(0vh) translateX(2vw);
  height: 30vh;
}`;

export const FindOutTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right:5vw;
  @media screen and (max-width: 768px) {
    margin-right:0;
 }
`;

export const FindOutText = styled.p`
  font: normal normal 500 3em Josefin Sans;
  color: #FFFFFF;
  margin-bottom:2.5vh;
  @media screen and (max-width: 992px) {
    font: normal normal 500 20px/24px Josefin Sans;
    margin-bottom:1vh;
  }
 `;

export const FindOutButtonDiv = styled.div`
  margin-bottom:2.5vh;
  text-align:center;
  @media screen and (max-width: 1080px) {
    margin-bottom:1vh;
  }
`;

export const SubscribeWrapper = styled.div`
  margin:2vh auto; 
  width:85%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fcff4b;
  border: 4px solid #FCFF4B;
  &:hover {
    box-shadow: 0 0 15px #dcf763;
  }
  @media screen and (max-width: 1080px) {
    margin:1vh auto; 
    width:95%;
    border: 1px solid #FCFF4B;
  }
`;
export const EmailInput = styled.input`
  width: 70%;
  height: 1.5em;
  font: normal normal normal 2em/1.5em Josefin Sans;
  letter-spacing: 0px;
  vertical-align: middle;
  padding:0 0.5vw;
  padding-top:0.55vh;
  color: #FFFFFF;
  opacity: 1;
  background-color: #FFA240;
  border:2px solid #FCFF4B;
  &::placeholder { 
    color: #fff;
    font: normal normal normal 1em/1em Josefin Sans;
    opacity: 1;
  }
  &:hover {
    box-shadow: 0 0 15px #dcf763;
    border:2px solid #FCFF4B;
    background-color: #E57F12;
  }
  &:focus-visible {
    box-shadow: 0 0 15px #dcf763;
    border:2px solid #FCFF4B;
  }
  @media screen and (max-width: 1080px) {
    font: normal normal normal 1em/1.5em Josefin Sans;
    padding-top:0.25vh;
  }
`;

export const SubscribeButton = styled.button`
  padding:0 0.5vw;
  padding-top:0.55vh;
  font: normal normal normal 2em Josefin Sans;
  letter-spacing: 0px;
  color: #4b4e2a;
  text-transform: uppercase;
  opacity: 1;
  background: #fcff4b;
  border: none;
  cursor: pointer;
  @media screen and (max-width: 1080px) {
    font: normal normal normal 1em/1.5em Josefin Sans;
    padding-top:0.25vh;
  }
`;

export const ErrorText = styled.h3`
  font: normal normal normal 1em/1.5em Josefin Sans;
  font-color: #FF0000;
  margin-left:5vw;
  @media screen and (max-width: 768px) {
    margin-left:2.5vw;
  }
`;

export const SubscribedAlert = styled.div`
  margin:2vh auto; 
  width:80%;
  border: 2px solid #FCFF4B;
  font: normal normal normal 1.5em/1.75em Josefin Sans;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0.25em 0em;
  padding-top:0.30em;
  @media screen and (max-width: 768px) {
    margin:1vh auto; 
    width:95%;
    border: 1px solid #FCFF4B;
  }
`;

export const FinishImg = styled.img`
  margin-top:2em;
  object-fit: cover;
  max-width:20em;
  @media screen and (max-width: 768px) {
    width:60%;
  }
`;

export const MusicIcon = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px;
`;

// global css classes
export const center = css`
  text-center;
`
export const bold = css`
  font-weight: bold;
`
