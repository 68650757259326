import { useState } from "react";
import { PURPOSE_PATH } from "../../constants/routeConstants";
import SudokuImg1Src from "../../assets/01/SudokuInterface-0.png";
import SudokuImg2Src from "../../assets/03/SudokuInterface-1.png";
import SudokuImg3Src from "../../assets/03/SudokuInterface-2.png";
import AppStoreImg from "../../assets/03/AppStore.png";
import PlayStoreImg from "../../assets/03/PlayStore.png";
import AppStorePressedImg from "../../assets/03/AppStore-PressedState.png";
import PlayStorePressedImg from "../../assets/03/PlayStore-PressedState.png";
import HalfWavesImg from "../../assets/02/WaveLines.png";
import { FindOutSection, Footer } from "../_Global/Global";
import { HalfWavesImage, PageHeading } from "../_Global/globalComponents";
import * as sc from "./styledComponents";

const StoreButton = (props: { src: string, pressedSrc: string, path: string, alt: string | undefined }) => {
  const [hover, setHover] = useState(false);
  return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
    <a href={props.path}>
      <sc.StoreImg src={hover ? props.pressedSrc : props.src} alt={props.alt} />
    </a>
  </div>
}
const Battle = () => {
  const [animationStatus, setAnimationStatus] = useState("Play");
  window.onpopstate = function (event) {
    setAnimationStatus("PlayReverse");
    setTimeout(() => {
      setAnimationStatus("Stop");
    }, 1000);
  };
  return <sc.Wrapper status={animationStatus}>
    <HalfWavesImage src={HalfWavesImg} status={animationStatus} />
    <sc.GamesWrapper>
      <sc.PaddingWrapper>
        <PageHeading>BATTLE</PageHeading>
        <sc.Section>
        <sc.ContentDiv>
            <sc.GameName>
              <h3>Sudoku:</h3>
              Unlock, Learn, and Battle
            </sc.GameName>
          <sc.RatingCard>
            <sc.GameText>
              Cross the charm with this epic memory based number puzzle game that lets you unlock,
              learn, and battle with friends making it easier for anyone to jump on.
            </sc.GameText>
          </sc.RatingCard>
          <sc.DownloadWrapper>
            <div id="#download"><sc.DownloadText>DOWNLOAD NOW AT</sc.DownloadText></div>
            <sc.StoresWrapper>
              <StoreButton src={AppStoreImg} path="https://testflight.apple.com/join/Kv6U1x5Y" pressedSrc={AppStorePressedImg} alt="AppStore" />
              <StoreButton src={PlayStoreImg} path="https://play.google.com/store/apps/details?id=fit.ok2.dots.beta" pressedSrc={PlayStorePressedImg} alt="PlayStore" />
            </sc.StoresWrapper>
          </sc.DownloadWrapper>
          </sc.ContentDiv>
          <sc.SecOneImageDiv>
      <sc.SudokuImg1 src={SudokuImg1Src} />
      <sc.SudokuImg2 src={SudokuImg2Src} />
      <sc.SudokuImg3 src={SudokuImg3Src} />
    </sc.SecOneImageDiv>
        </sc.Section>
      </sc.PaddingWrapper>
      <FindOutSection button="our mission" path={PURPOSE_PATH} title="Ok2 Company!" />
      <Footer />
    </sc.GamesWrapper>
  </sc.Wrapper>
};

export default Battle;
