import styled from "@emotion/styled";

import { SlideDown } from "../_Global/globalComponents";
type AnimationProps = {
  status: string;
};

export const Container = styled.div`
  z-index: 1;
  background: #112a34;
  height:100vh;
  overflow:hidden;
  position: relative;
  animation: ${(props: AnimationProps) =>
    props.status === "Play" ? SlideDown : "none"}
    1s linear 1;
`;

export const Logo = styled.img`
  margin-left :2vh ;
  margin-top:2vh;
  max-width: 40vw;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  height:45vh;
  max-height: 45vh;
  width: 100%;
  object-fit: cover;
  z-index: -1;
`;
export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const WavesDiv = styled.div`
  padding-top:2vh;
  width:100vw;
`;

export const SketchDiv = styled.div`
  position:absolute;
  z-index:0;
  height:100%;
  max-height:70vh;
  bottom:0;
`;

export const SudokuWrapper = styled.div`
  margin:2vh auto;
  padding-left:2vw;
  width:65vw;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  @media screen and (max-width: 1080px) {
      width: 95vw;
      margin-left:0;
      margin-top:0;
      flex-direction:column;
    }
`;

export const SudokuImgDiv = styled.div`
  text-align: center;
  margin-right:2vw;
  z-index:1;
  @media screen and (max-width: 1080px) {
      margin:2.5vh 0;
    }
`;

export const SudokuText = styled.p`
  font: normal normal 600 28px/32px Josefin Sans;
  margin-bottom:1vh;
  letter-spacing: 0px;
  color:#FCFF4B;
`;
export const SudokuImg = styled.img`

`;

export const SudokuCard = styled.div`
  padding:2vh 1vw;
  border:0.5em solid #FCFF4B;
  border-radius:2em 0px 2em 0px;
  display:flex;
  align-items:center;
  background: #112a34;
  z-index:1;
  @media screen and (max-width: 1080px) {
      padding:0.2em;
      width:85vw;
      border:0.25em solid #FCFF4B;
      border-radius:1em 0px 1em 0px;
    }
`;

export const SudokuCardImg = styled.img`
  width:25%;
  margin-right:1em;
  @media screen and (max-width: 1080px) {
      width:0;
      
    }
`;

export const SudokuCardText = styled.p`
  font: normal normal 500 40px/52px Josefin Sans;
  letter-spacing: 0px;
  color:#FCFF4B;
  @media screen and (max-width: 1080px) {
      font: normal normal 500 1.5em/1.25em Josefin Sans;
    }
`;

export const ButtonsDiv = styled.div`
  display:flex;
  z-index:1;
  flex:1;
  align-items:center;
  width:100%;
  min-height:10vh;
  max-height:30%;
  justify-content: center;
  @media screen and (max-width: 1080px) {
    justify-content: space-evenly;
     max-height:15vh;
  }
`;

export const ImgButton = styled.img`
  max-width:10vw;
  margin-left:18vw;
  margin-right:5vw;
  @media screen and (max-width: 1080px) {
    max-width:30vw;
    margin-right:0vw;
    margin-left:0vw;
  }
`;