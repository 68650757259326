import { initializeApp } from 'firebase/app';
import { addDoc, collection, getFirestore } from 'firebase/firestore/lite';
import firebaseProdConfig from './firebase-prod-config';
import firebaseDevConfig from './firebase-dev-config';
const config = process.env.REACT_APP_ENV === 'prod' ? firebaseProdConfig : firebaseDevConfig;
const app = initializeApp(config);
const database = getFirestore(app);

const addEmail = async (email: string) => {
  return await addDoc(collection(database, "website"), {
    email
  });
}
export { addEmail };
