import { useState } from "react";
import HalfWavesImg from "../../assets/02/WaveLines.png";
import Img0 from "../../assets/04/Box-0.png";
import Img1 from "../../assets/04/Box-1.png";
import Img2 from "../../assets/04/Box-2.png";
import Img3 from "../../assets/04/Box-3.png";
import Img4 from "../../assets/04/Box-4.png";
import Img5 from "../../assets/04/Box-5.png";
import Img6 from "../../assets/04/Box-6.png";
import Youtube from "../../assets/04/Youtube.svg";
import Linkedin from "../../assets/04/Linkedin.svg";
import * as sc from "./styledComponents";

import { HalfWavesImage } from "../_Global/globalComponents";
import { FinishImage, Footer } from "../_Global/Global";

const Author = () => {
  return (
    <sc.AuthorDiv>
      <br />
      <sc.AuthorNameDiv>
        <sc.AuthorName>Dr. Sambasiva R Korrapolu</sc.AuthorName>
        <sc.AuthorSocialDiv>
          <a href="https://www.linkedin.com/in/yogacharya-dr-sambasivareddy-korrapolu-604919112/">
            <sc.SocialImage src={Linkedin} />
          </a>
          <sc.Line />
          <a href="https://www.youtube.com/c/KorrapoluSambaSivaReddy">
            <sc.SocialImage src={Youtube} />
          </a>
        </sc.AuthorSocialDiv>
      </sc.AuthorNameDiv>
      <sc.AuthorDetailsList>
        <sc.PurposeListItem>
          Founder of{" "}
          <a href="https://www.viyoga.org/">Vivekananda Yoga Academy</a>
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Passionately teaching Yoga for the past 20 years
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Thousands of yoga sadhaks benefitted from his teachings
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Solved variety of health problems with Yoga techniques
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Survived a deadly accident at the age of 76
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Survived prostate cancer at the age of 80
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Started youtube videos during pandemic times
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Got his first Ph.D. at the age of 78 in Kundalini Yoga
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Got his second Ph.D. at they age of 83 in Yoga Philosophy.
        </sc.PurposeListItem>
      </sc.AuthorDetailsList>
      <br />
      <sc.AuthorNameDiv>
        <sc.AuthorName>Shankar Korrapolu</sc.AuthorName>
        <sc.AuthorSocialDiv>
          <a href="https://www.linkedin.com/in/shankar-korrapolu-3743881/">
            <sc.SocialImage src={Linkedin} />
          </a>
          <sc.Line />
          <a href="https://www.youtube.com/channel/UCv3_Kai7gaBHvRUkA9u6_IA/featured">
            <sc.SocialImage src={Youtube} />
          </a>
        </sc.AuthorSocialDiv>
      </sc.AuthorNameDiv>
      <sc.AuthorDetailsList>
        <sc.PurposeListItem>Survived stage 4 cancer</sc.PurposeListItem>
        <sc.PurposeListItem>
          Created a mathematical model to represent a digital twin
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Helping dozens of cancer patients with Fasting, Food as a Medicine and
          Yoga
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Created healthy lifestyle patterns by encouraging to change behavioral
          unhealthy lifestyles
        </sc.PurposeListItem>
        <sc.PurposeListItem>
          Working with the Biden’s administration Moonshot project to reduce
          cancer by 50% in the next 25 years.
        </sc.PurposeListItem>
      </sc.AuthorDetailsList>
    </sc.AuthorDiv>
  );
};

const Purpose = () => {
  const [animationStatus, setAnimationStatus] = useState("Play");
  window.onpopstate = function (event) {
    setAnimationStatus("PlayReverse");
    setTimeout(() => {
      setAnimationStatus("Stop");
    }, 1000);
  };
  return (
    <sc.Wrapper status={animationStatus}>
      <HalfWavesImage src={HalfWavesImg} status={animationStatus} />
      <sc.PurposeWrapper>
        <sc.PaddingWrapper>
          <sc.ContentDiv>
            <sc.TextDiv>
              <sc.PurposeHeading>
                <b>OK2</b>
                <br /> is for homeostatis
              </sc.PurposeHeading>
              <br />
              <br />
              <sc.PurposeText>
                If there is one thing COVID-19 has taught humanity is how to
                live by improving our healthy lifestyles. An important strategy
                to fight a pandemic like this is to be ready with preparedness
                keeping our health as best as one can keep. Each of us needs to
                learn how to be proactive in case of any eventuality. Despite
                knowing many best practices, most of us are unable to maintain
                healthy lifestyles.
              </sc.PurposeText>
              <sc.PurposeText>
                The founders of this company have succeeded in beating deadly
                cancers by experimenting with a variety of techniques with
                foods, fasting and yoga exercises. It wasn't easy to follow
                these techniques as the temptations and environment is not
                conducive. After decades of research in understanding the
                science behind these techniques and replicating the same success
                stories on many people, suffering with various ailments, we have
                decided to bring these techniques to the masses using mobile
                games.
              </sc.PurposeText>
              <sc.PurposeText>
                We love playing games to get some dopamine, whereas following
                healthy lifestyles is not very exciting as there is no instant
                gratification.
              </sc.PurposeText>
              <sc.PurposeText>
                Most of us were born without any diseases. But, what is
                happening to our bodies to become sick. Our body has been taking
                stuff through five sensory inputs, such as food and drinks
                through mouth, air through nose, audio through ears, video
                through eyes, touch and temperature through skin. By modeling
                the input patterns of a person into a digital twin, the game can
                predict the future scenarios with some consequences.
              </sc.PurposeText>
              <sc.PurposeText>
                We are programmed to depend on the healthcare system, which is
                designed to be reactive rather than proactively preventing
                sickness. Pouring the healthcare system on the sickness of fire
                doesn’t stop if unhealthy lifestyles continue. For certain
                proven prescriptions, emergencies and surgeries, the current
                healthcare system does justice, which is 50% of the solution.
                The remaining 50% squarely relies on the individual’s healthy
                lifestyles. As long as unhealthy lifestyles continue, the
                healthcare system loves repeat customers.
              </sc.PurposeText>
              <sc.PurposeText>
                All of us are made up of elements of the periodic table. These
                elements combine into molecules, which turn into DNA to print a
                copy of themselves. These mobile games will teach these skills
                for you to understand the root causes why we get sick.
              </sc.PurposeText>
              <sc.PurposeText>
                We are building mobile games applying three strategies
                addressing mental and physical aspects.
              </sc.PurposeText>
              <sc.PurposeOuterList>
                <sc.PurposeListItemBold>
                  Food as a Medicine:
                </sc.PurposeListItemBold>
                <sc.PurposeList>
                  <sc.PurposeListItem>
                    Food is an addiction. Games guide us to slowly get rid of
                    this addiction.
                  </sc.PurposeListItem>
                  <sc.PurposeListItem>
                    Homeostasis is a way to keep our health in equilibrium by
                    controlling the dial gauges that feed the stuff into our
                    bodies.
                  </sc.PurposeListItem>
                </sc.PurposeList>
              </sc.PurposeOuterList>
              <br />
              <sc.PurposeOuterList>
                <sc.PurposeListItemBold>Fasting:</sc.PurposeListItemBold>
                <sc.PurposeList>
                  <sc.PurposeListItem>
                    Changes that happen in our bodies when fasting can be
                    simulated in a digital twin. Autophagy is a way to cleanse
                    our body from toxins and minimize the unwanted stuff.
                  </sc.PurposeListItem>
                  <sc.PurposeListItem>
                    Fasting can be made fun as a community.
                  </sc.PurposeListItem>
                </sc.PurposeList>
              </sc.PurposeOuterList>
              <br />
              <sc.PurposeOuterList>
                <sc.PurposeListItemBold>Yoga:</sc.PurposeListItemBold>
                <sc.PurposeList>
                  <sc.PurposeListItem>
                    Improvements can be done by taking a baseline of the health
                    parameters and constantly trying to improve healthy metrics.
                  </sc.PurposeListItem>
                  <sc.PurposeListItem>
                    Yoga is all about checking the capabilities of organs.
                  </sc.PurposeListItem>
                </sc.PurposeList>
              </sc.PurposeOuterList>
              <br />
              <sc.PurposeText>
                Hence for unparalleled healthy entertainment, we offer games in
                3 categories:
              </sc.PurposeText>
              <sc.PurposeList>
                <sc.PurposeOuterList>
                  <sc.PurposeListItem>Casual</sc.PurposeListItem>
                </sc.PurposeOuterList>
                <sc.PurposeOuterList>
                  <sc.PurposeListItem>
                    Learning (not to be confused with education)
                  </sc.PurposeListItem>
                </sc.PurposeOuterList>
                <sc.PurposeOuterList>
                  <sc.PurposeListItem>Health and Wellness</sc.PurposeListItem>
                </sc.PurposeOuterList>
              </sc.PurposeList>
              <Author />
            </sc.TextDiv>
            <sc.ImagesDiv>
              <sc.BoxImageContainer>
                <sc.BoxImage src={Img0} delay="0.1"/>
              </sc.BoxImageContainer>
              <sc.BoxImageContainer className={sc.right}>
                <sc.BoxImage src={Img1} delay="0.8"/>
              </sc.BoxImageContainer>
              <sc.BoxImageContainer>
                <sc.BoxImage src={Img2} delay="0.5"/>
              </sc.BoxImageContainer>
              <sc.BoxImageContainer className={sc.right}>
                <sc.BoxImage src={Img3} delay="0.8"/>
              </sc.BoxImageContainer>
              <sc.BoxImageContainer>
                <sc.BoxImage src={Img4} delay="0.4"/>
              </sc.BoxImageContainer>
              <sc.BoxImageContainer className={sc.right}>
                <sc.BoxImage src={Img5} delay="0.8"/>
              </sc.BoxImageContainer>
              <sc.BoxImageContainer>
                <sc.BoxImage src={Img6} delay="0.1"/>
              </sc.BoxImageContainer>
            </sc.ImagesDiv>
          </sc.ContentDiv>
          <FinishImage />
        </sc.PaddingWrapper>
        <Footer />
      </sc.PurposeWrapper>
    </sc.Wrapper>
  );
};
export default Purpose;
