import styled from "@emotion/styled";
import * as gc from "../_Global/globalComponents";

type AnimationProps = {
  status: string;
};


export const Wrapper = styled.div`
  background-color: #112a34;
  animation: ${(props: AnimationProps) =>
      props.status === "Play" ? gc.SlideDown : "none"}
    1s linear 1;
  animation-timing-function: ease-out;
  position: relative;
  height: 100%;
`;

export const GamesWrapper = styled.div`
  width: 80vw;
  left:10vw;
  position: absolute;
  padding-top: 5vh;
  top:20vh;
  background: transparent
    linear-gradient(181deg, #f8f32b 0%, #dad62f 18%, #f6f47f 54%, #fffd98 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
  @media screen and (max-width: 1080px) {
   top:10vh;
   padding-top: 2vh;
  }
`;

export const PaddingWrapper = styled.div`
  padding-left: 5em;
  padding-right: 2em;
  @media screen and (max-width: 1080px) {
    padding:0 1.5em;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 1080px) {
    flex-direction: column;
   }
`;

export const GameName = styled.p`
  font: normal normal 600 2.25em/1.5em Josefin Sans;
  letter-spacing: 0px;
  color: #0e1428;
  opacity: 1;
  margin-bottom:1.5vh;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 24px/26px Josefin Sans;
    text-align: left;
  }
`;

export const RatingSection = styled.div`
  margin-right:3vw;
  @media screen and (max-width: 1080px) {
    margin-top:2em;
    margin-right:0vw;
  }`;

export const SectionOneGameNameWrapper = styled.div`
  width:28vw;
  @media screen and (max-width: 1080px) {
   width:100%;
  }
`;

export const RatingCard = styled.div`
  width:28vw;
  padding: 2em;
  background: #ff8811 0% 0% no-repeat padding-box;
  border-radius: 0px 24px;
  opacity: 1;
  border: none;
  @media screen and (max-width: 1080px) {
    width: 90%;
    margin: 0 auto;
    padding: 1em;
   }
`;

export const RatingHeading = styled.p`
  font: normal normal bold 28px/38px Josefin Sans;
  letter-spacing: 0px;
  color: #fff4f0;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal bold 24px/36px Josefin Sans;
   }
`;

export const StarDiv = styled.div`
  display:flex;
  margin-bottom:0.5em;
`;

export const Star = styled.img`
  color:#ECF320 !important;
  height:24px;
  width:24px;
  margin:0 0.1em;
`;

export const RatingValue = styled.h4`
  margin-left:5px;
  font-size:20px;
  font-weight:400;
  color:#ECF320;
`;

export const GameText = styled.p`
  font: normal normal normal 22px/28px Josefin Sans;
  color: #fff4f0;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal 500 16px/18px Josefin Sans;
  }
`;

export const FeaturesHeading = styled.p`
  font: normal normal bold 24px Josefin Sans;
  letter-spacing: 0px;
  color: #fcff4b;
  opacity: 1;
  margin:1em 0;
  margin-bottom:0.75em;
  @media screen and (max-width: 1080px) {
    font: normal normal 500 20px/24px Josefin Sans;
  }
`;
export const FeaturesWrapper = styled.div`
`;

export const Feature = styled.div`
display:flex;
`;

export const BulletImg = styled.img`
  height:9px;
  width:9px;
  margin-right:0.25em;
  margin-top:6px;
`;

export const SectionOne  = styled.div`
  display: flex;
  justify-content: space-around;
  align-items:center;
  @media screen and (max-width: 1080px) {
    flex-direction: column-reverse;
    margin-bottom:4vh;
  }
`;

export const FeaturesText = styled.p`
  font: normal normal normal 20px/24px Josefin Sans;
  vertical-align: start;
  color: #fff4f0;
  opacity: 1;
  margin-bottom:0.5em;
  @media screen and (max-width: 1080px) {
    font: normal normal 500 16px/18px Josefin Sans;
  }
`;

export const DownloadWrapper = styled.div`
  margin:4vh auto;
  width:100%;
`;

export const DownloadText = styled.p`
  font: normal normal 600 42px/58px Josefin Sans;
  letter-spacing: 0px;
  color: #0e1428;
  opacity: 1;
  margin:0.5em 0;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 24px/26px Josefin Sans;
  }
`;

export const StoresWrapper = styled.div`
  display: flex;
`;

export const StoreImg = styled.img`
  height:5em;
  margin-right:1em;
  @media screen and (max-width: 1080px) {
    height:2.5em;
    margin-right:0.5em;
  }
`;

export const SecOneImageDiv = styled.div`
  height:75vh;
  width:80%;
  max-width:32vw;
  position:relative;
  @media screen and (max-width: 1080px) {
    height: 50vh;
    width:100%;
    max-width:70vw;
    margin-top:4vh;
  }
`;

export const SecTwoImageDiv = styled.div`
  height:80vh;
  margin:0 2vw;
  margin-left:4vw;
  @media screen and (max-width: 1080px) {
    height: 30vh;
    width:100%;
    margin:0 auto;
  }
`;
// [top,r/l,mobile-top,mobiler/l,change,time]
export const SudokuImg1 = gc.sudokuImgRight([-10,0,-5,-1,1,10]);
export const SudokuImg2 = gc.sudokuImgLeft([5,-2,5,0,0,10]);
export const SudokuImg3 = gc.sudokuImgRight([30,-1,18,-3,-1.5,8]);

export const SecTwoGame = styled.div`
  margin-left:12vw;
  width: 100%;
  @media screen and (max-width: 1080px) {
    margin-left:0vw;
  }`;

export const HealthImg1 = styled.img`
  width: 22vw;
  height: 58vh; 
  position: relative;
  top: -35vh;
  right: 5vw;
  animation: ${gc.moveRandomRight([-35,5,0,0,1])} 15s linear infinite;
  @media screen and (max-width: 1080px) {
    width: 40vw;
    height: 30vh;
    top: -27.5vh;
    right: 0vw;
    animation: ${gc.moveRandomRightMobile([-27.5,0])} 15s linear infinite;
   }
`;

export const HealthImg2 = styled.img`
  width: 20vw;
  height: 52vh; 
  position: relative;
  top: 20vh;
  right: -10vw;
  animation: ${gc.moveRandomRight([20,-10,0,0,1])} 15s linear infinite;
  @media screen and (max-width: 1080px) {
    width: 40vw;
    height: 25vh;
    top: 0vh;
    left: -28vw;
    animation: ${gc.moveRandomLeftMobile([0,28])} 15s linear infinite;
   }
`;

