import { useEffect, useState } from "react";
import HomeImage from "../../assets/01/OK2Banner.png";

import WaveGif from "../../assets/01/Wave.png";
import VideoSrc from "../../assets/videos/BG.mp4";

import { useNavigate } from "react-router-dom";
import * as sc from "./styledComponents";
import { TERRAIN_PATH } from "../../constants/routeConstants";

const Home = () => {
  const navigate = useNavigate();
  const [animationStatus, setAnimationStatus] = useState("Pause");
  const [slide, setSlide] = useState(0);

  function updateSlide() {
    setSlide((slide + 1) % 4);
  }

  window.onpopstate = function (event) {
    setAnimationStatus("PlayReverse");
    setTimeout(() => {
      setAnimationStatus("Stop");
    }, 1000);
  };

  var bannerText = [
    "Engage with NEMES digital twin",
    "Empower by food, fasting and yoga",
    "Entertain through puzzle games"
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      updateSlide();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  });

  const showWaves = () => {
    setAnimationStatus("Play");
    setTimeout(() => {
      navigate(TERRAIN_PATH);
      setAnimationStatus("Stop");
    }, 1000);
  }

  return (
    <sc.HomeWrapper status={animationStatus}>
      <sc.Overlay><sc.Video src={VideoSrc} loop muted autoPlay preload="none" />
      </sc.Overlay>
      <sc.HomeImg src={HomeImage} alt="HomeImage"/>
      {
        slide !== 0 ? <sc.TextWrapper>
          <sc.Text>{bannerText[slide - 1]}</sc.Text>
        </sc.TextWrapper>
          : <sc.TextWrapper>
            <sc.Text>ENGAGE</sc.Text>
            <sc.Dot />
            <sc.Text>EMPOWER</sc.Text>
            <sc.Dot />
            <sc.Text>ENTERTAIN</sc.Text>
          </sc.TextWrapper>
      }
      <sc.HomeGif
        src={WaveGif}
        alt="WaveGif"
      />
      <sc.EnterButtonDiv><sc.EnterButton onClick={showWaves}>CLICK TO ENTER</sc.EnterButton></sc.EnterButtonDiv>
    </sc.HomeWrapper>
  );
};


export default Home;

