import styled from "@emotion/styled";
import * as gc from "../_Global/globalComponents";

type AnimationProps = {
    status: string;
  };
  
export const Wrapper = styled.div`
  background-color: #112a34;
  animation: ${(props: AnimationProps) =>
      props.status === "Play" ? gc.SlideDown : "none"}
    1s linear 1;
  animation-timing-function: ease-out;
  position: relative;
  height: 100%;
`;

export const GamesWrapper = styled.div`
  width: 80vw;
  left:10vw;
  position: absolute;
  padding-top: 5vh;
  top:20vh;
  background: transparent
    linear-gradient(181deg, #f8f32b 0%, #dad62f 18%, #f6f47f 54%, #fffd98 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
  @media screen and (max-width: 1080px) {
   top:10vh;
   padding-top: 2vh;
  }
`;

export const PaddingWrapper = styled.div`
  padding-left: 5em;
  padding-right: 2em;
  display:block;
  @media screen and (max-width: 1080px) {
    padding:0 1.5em;
  }
`;

export const TermsImage = styled.img`
  width:70%;
  margin:2em 0;
`;

export const TermsWrapper = styled.div`
  width: 80vw;
  left:10vw;
  position: absolute;
  padding-top: 5vh;
  top:20vh;
  background: transparent
    linear-gradient(181deg, #f8f32b 0%, #dad62f 18%, #f6f47f 54%, #fffd98 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
  @media screen and (max-width: 1080px) {
   top:10vh;
   padding-top: 2vh;
  }
`;

export const TermsTitle = styled.h2`
  text-align: left;
  font: normal normal normal 4em Josefin Sans;
  letter-spacing: 0px;
  color: #D8315B;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal 500 2em Josefin Sans;
   }
`;

export const TermsText = styled.p`
  margin-top:1em;
  text-align: left;
  font: normal normal normal 2em/1.5em Josefin Sans;
  letter-spacing: 0px;
  color: #4B4E2A;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal normal 1.25em/1.25em Josefin Sans;
   }
`;

export const SectionTitle = styled.h2`
  text-align: left;
  font: normal normal normal 2.5em/2em Josefin Sans;
  letter-spacing: 0px;
  color: #342E37;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal normal 1.5em/1.25em Josefin Sans;
   }
`;

export const SectionList = styled.ul`
  list-style-position: outside;
  margin-left: 2vw;
  @media screen and (max-width: 1080px) {
    margin-left:5vw;
  }
`

export const SectionListItem = styled.li`
  font: normal normal normal 1.75em/1.65em Josefin Sans;
  vertical-align: top;
  color: #4B4E2A;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal normal 1.25em/1.25em Josefin Sans;
  }
  margin:0.5em 0;
`;

export const FinishImg = styled.img`
  margin-top:2em;
  object-fit: cover;
  width:30%;
  @media screen and (max-width: 1080px) {
    width:60%;
  }
`;