import { useState } from "react";
import { PURPOSE_PATH } from "../../constants/routeConstants";
import SudokuImg1 from "../../assets/01/SudokuInterface-0.png";
import SudokuImg2 from "../../assets/03/SudokuInterface-1.png";
import SudokuImg3 from "../../assets/03/SudokuInterface-2.png";
import HealthImg1 from "../../assets/03/AyuhInterface-0.png";
import HealthImg2 from "../../assets/03/AyuhInterface-1.png";
import AppStoreImg from "../../assets/03/AppStore.png";
import PlayStoreImg from "../../assets/03/PlayStore.png";
import AppStorePressedImg from "../../assets/03/AppStore-PressedState.png";
import PlayStorePressedImg from "../../assets/03/PlayStore-PressedState.png";
import HalfWavesImg from "../../assets/02/WaveLines.png";
import circleImg from "../../assets/03/circle-line.svg";
import star from "../../assets/03/star.svg";
import { FindOutSection, Footer } from "../_Global/Global";
import { HalfWavesImage, PageHeading } from "../_Global/globalComponents";
import * as sc from "./styledComponents";

const StoreButton = (props: { src: string, pressedSrc: string, path: string, alt: string | undefined }) => {
  const [hover, setHover] = useState(false);
  return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
    <a href={props.path}>
      <sc.StoreImg src={hover ? props.pressedSrc : props.src} alt={props.alt} />
    </a>
  </div>
}

const ComponentOne = () => (
  <sc.RatingCard>
    <sc.RatingHeading>RATINGS:</sc.RatingHeading>
    <sc.StarDiv>
      <sc.Star src={star} />
      <sc.Star src={star} />
      <sc.Star src={star} />
      <sc.Star src={star} />
      <sc.Star src={star} />
      <sc.RatingValue><sup>5</sup></sc.RatingValue>
    </sc.StarDiv>
    <sc.GameText>
      Cross the charm with this epic memory based number puzzle game that lets you unlock,
      learn, and battle with friends making it easier for anyone to jump on.
    </sc.GameText>
    <sc.FeaturesHeading>FEATURES</sc.FeaturesHeading>
    <sc.FeaturesWrapper>
      <sc.Feature><sc.BulletImg src={circleImg} /><sc.FeaturesText>Play for FREE with players across the world</sc.FeaturesText></sc.Feature>
      <sc.Feature><sc.BulletImg src={circleImg} /><sc.FeaturesText>Power the brain to solve epic hard puzzles</sc.FeaturesText></sc.Feature>
      <sc.Feature><sc.BulletImg src={circleImg} /><sc.FeaturesText>Learn top skills to crack the code</sc.FeaturesText></sc.Feature>
      <sc.Feature><sc.BulletImg src={circleImg} /><sc.FeaturesText>Battle with bots and friends on the same puzzle</sc.FeaturesText></sc.Feature>
      <sc.Feature><sc.BulletImg src={circleImg} /><sc.FeaturesText>Scan paper boards and play together</sc.FeaturesText></sc.Feature>
    </sc.FeaturesWrapper>
  </sc.RatingCard>
);

const ComponentTwo = () => (
  <sc.RatingCard>
    <sc.RatingHeading>RATINGS:</sc.RatingHeading>
    <sc.StarDiv>
      <sc.Star src={star} />
      <sc.Star src={star} />
      <sc.Star src={star} />
      <sc.Star src={star} />
      <sc.Star src={star} />
      <sc.RatingValue><sup>5</sup></sc.RatingValue>
    </sc.StarDiv>
    <sc.GameText>
      Get hooked with monthy challenges that enpower the body and mind,
      to beat the deadly disease. Awaken the mighty consciousness, all powers are within you!
    </sc.GameText>
    <sc.FeaturesHeading>FEATURES</sc.FeaturesHeading>
    <sc.FeaturesWrapper>
      <sc.Feature><sc.BulletImg src={circleImg} /><sc.FeaturesText>Digital twin game to predict the consequences</sc.FeaturesText></sc.Feature>
      <sc.Feature><sc.BulletImg src={circleImg} /><sc.FeaturesText>Mightly monthyl challenges to beat the beast</sc.FeaturesText></sc.Feature>
      <sc.Feature><sc.BulletImg src={circleImg} /><sc.FeaturesText>Peace of mind with breathing exercises</sc.FeaturesText></sc.Feature>
      <sc.Feature><sc.BulletImg src={circleImg} /><sc.FeaturesText>Everyday tracking for the ultimate visibility</sc.FeaturesText></sc.Feature>
      <sc.Feature><sc.BulletImg src={circleImg} /><sc.FeaturesText>Beach juice can be healthy also</sc.FeaturesText></sc.Feature>
    </sc.FeaturesWrapper>
  </sc.RatingCard>
);

const SectionOne = () => (
  <sc.SectionOne>
    <sc.RatingSection>
      <sc.SectionOneGameNameWrapper>
        <sc.GameName>
          Sudoku:
          <br /> Unlock, Learn, and Battle
        </sc.GameName>
      </sc.SectionOneGameNameWrapper>
      <ComponentOne />
      <sc.DownloadWrapper>
        <sc.DownloadText>DOWNLOAD NOW AT</sc.DownloadText>
        <sc.StoresWrapper>
          <StoreButton src={AppStoreImg} path="https://testflight.apple.com/join/Kv6U1x5Y" pressedSrc={AppStorePressedImg} alt="AppStore"/>
          <StoreButton src={PlayStoreImg} path="https://play.google.com/store/apps/details?id=fit.ok2.dots.beta" pressedSrc={PlayStorePressedImg} alt="PlayStore"/>
        </sc.StoresWrapper>
      </sc.DownloadWrapper>
    </sc.RatingSection>
    <sc.SecOneImageDiv>
      <sc.SudokuImg1 src={SudokuImg1} />
      <sc.SudokuImg2 src={SudokuImg2} />
      <sc.SudokuImg3 src={SudokuImg3} />
    </sc.SecOneImageDiv>
  </sc.SectionOne>
);

const SectionTwo = () => (
  <sc.Section>
    <sc.SecTwoImageDiv>
      <sc.HealthImg2 src={HealthImg2} />
      <sc.HealthImg1 src={HealthImg1} />
    </sc.SecTwoImageDiv>
    <sc.SecTwoGame>
      <sc.GameName>
        Ayuh:
        <br /> Food, Fasting and Yoga strategies
      </sc.GameName>
      <ComponentTwo />
    </sc.SecTwoGame>
  </sc.Section>
);


const Games = () => {
  const [animationStatus, setAnimationStatus] = useState("Play");
  window.onpopstate = function (event) {
    setAnimationStatus("PlayReverse");
    setTimeout(() => {
      setAnimationStatus("Stop");
    }, 1000);
  };
  return <sc.Wrapper status={animationStatus}>
    <HalfWavesImage src={HalfWavesImg} status={animationStatus} />
    <sc.GamesWrapper>
      <sc.PaddingWrapper>
        <PageHeading>CURRENT GAMES</PageHeading>
        <SectionOne />
        <SectionTwo />
      </sc.PaddingWrapper>
      <FindOutSection button="our mission" path={PURPOSE_PATH} title="Ok2 Company!" />
      <Footer />
    </sc.GamesWrapper>
  </sc.Wrapper>
};

export default Games;
