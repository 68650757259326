import styled from "@emotion/styled";
import { slideupAnimationFunction, slideupAnimationTimingFunction } from "../_Global/globalComponents";

export const HomeWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #112A34;
  position: relative;
  animation: ${slideupAnimationFunction} 1s linear 1;
  animation-timing-function: ${slideupAnimationTimingFunction}
`;


export const Overlay = styled.div`
  opacity: 0.5;
`;

export const Video = styled.video`
  position: fixed;  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  @media screen and (max-width: 1080px) {
    display:none;
  }
`;

export const HomeImg = styled.img`
  width: 50vw;
  margin: 2vh 0;
  z-index: 5;
  @media screen and (max-width: 1080px) {
    width: 90%;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  margin: 2vh 0;
  z-index: 5;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.p`
  text-align: left;
  font: normal normal 500 48px/60px Avenir;
  letter-spacing: 0px;
  color: #DBEFFF;
  z-index: 5;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font-size: 1.25em;
  }
`;

export const Dot = styled.span`
  height: 1.75vh;
  width: 1.75vw;
  background: #DBEFFF 0% 0% no-repeat padding-box;
  clip-path: circle();
  margin: auto 2vw;
  opacity: 1;
  z-index: 5;
`;

export const HomeGif = styled.img`
  width: 22vw;
  cursor: pointer;
  margin: 2vh 0;
  z-index: 5;
  @media screen and (max-width: 1080px) {
    width: 45vw;
  }
`;

export const EnterButtonDiv = styled.div`
  color: #DBEFFF;
  padding:0 2vw;
  transition: 0.2s;
  border-radius: 5px;
  z-index: 5;
  &:hover {
    color: #DCF763;
    text-decoration: underline;
    background-color: #0F3D4E;
  }
`;

export const EnterButton = styled.p`
  font-size: 1.65em;
  font-weight: 500;
  z-index: 5;
  margin: 2vh 0;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
  @media screen and (max-width: 1080px) {
    font-size: 1em;
  }
`;