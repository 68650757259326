import styled from "@emotion/styled";

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Heading = styled.p`
  margin: 5vh 0;
  text-align: center;
  font: normal normal 500 5em Josefin Sans;
  letter-spacing: 0px;
  color: #fff4f0;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 2em Josefin Sans;
    margin: 2.5vh 0;
  }
`;