import styled from "@emotion/styled";
import PurposeBg from "../../assets/02/PurposeBG.png";
import { SlideDown } from "../_Global/globalComponents";

type AnimationProps = {
  status: string;
};

export const Wrapper = styled.div`
  background-color: #112a34;
  animation: ${(props: AnimationProps) =>
    props.status === "Play" ? SlideDown : "none"}
    1s linear 1;
  animation-timing-function: ease-out;
  position: relative;
  height: 100%;
`;

export const MetaverseWrapper = styled.div`
  width: 80vw;
  left:10vw;
  position: absolute;
  padding-top: 5vh;
  top:20vh;
  background: transparent
    linear-gradient(181deg, #f8f32b 0%, #dad62f 18%, #f6f47f 54%, #fffd98 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
  @media screen and (max-width: 1080px) {
   top:10vh;
   padding-top: 2vh;
  }
`;

export const MetaverseHeading = styled.p`
  margin: 5vh 0;
  text-align: center;
  font: normal normal 500 4em Josefin Sans;
  letter-spacing: 0px;
  color: #0e1428;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 1.75em Josefin Sans;
    margin: 2.5vh 0;
  }
`;

export const AboutCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 2vw;
  padding-top: 4vh;
  text-align: left;
  background: #dcf763 0% 0% no-repeat padding-box;
  border: 15px solid #32533d;
  border-radius: 70px 0px;
  opacity: 1;
  margin: 0 5vw;
  margin-bottom: 3vh;
  @media screen and (max-width: 1080px) {
    border: 4px solid #32533d;
    border-radius: 40px 0px;
    padding-top: 2vh;
    margin-bottom: 2vh;
   }
`;

export const AboutTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top:2vh;
  @media screen and (max-width: 1080px) {
    margin-top:0vh;
   }
`;

export const AboutHeading = styled.p`
  font: normal normal 600 3.5em Josefin Sans;
  letter-spacing: 0px;
  color: #e24e1b;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font-size: 1.75em;
   }
`;

export const AboutText = styled.p`
  font: normal normal normal 58px/68px Josefin Sans;
  font-size: 52px;
  letter-spacing: 0px;
  color: #542344;
  opacity: 1;
  width: 95%;
  margin-top:2vh;
  margin-bottom:4vh;
  @media screen and (max-width: 1080px) {
    font-size: 1.5em;
    line-height: 1.25;
    margin-top:0;
    margin-bottom:0;
  }
`;

export const MetaverseImage = styled.img`
  height:35vh;
  object-fit: cover;
  @media screen and (max-width: 1080px) {
    width: 0;
    height: 0;
  }
`;

export const MetaverseImageWrapper = styled.div`
display: flex;
justify-content: space-between;
`;

export const MetaverseImageMobile = styled.img`
  width: 0vw;
  object-fit: contain;
  @media screen and (max-width: 1080px) {
   width: 10vw;
   height: 10vw;
  }
`;

export const FoundersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw;
  margin-bottom: 5vh;
  @media screen and (max-width: 1080px) {
   flex-direction: column;
   margin-bottom: 2.5vh;
  }
`;

export const TeamsCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4vw;
  @media screen and (max-width: 1080px) {
    width:100%;
    align-items: flex-start;
    margin-right: 0vw;
    margin-bottom:0.5vh;
    margin-top:1.5vh;
   }
`;

export const TeamsHeading = styled.h1`
  font: normal normal 600 3em/2em Josefin Sans;
  letter-spacing: 0px;
  color: #0e1428;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 1.65em Josefin Sans;
   }
`;

export const FoundersText = styled.p`
  font: normal normal 600 3em/2em Josefin Sans;
  letter-spacing: 0px;
  color: #e24e1b;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 1.65em Josefin Sans;
   }
`;

export const FounderCard = styled.div`
  background: #ff8811 0% 0% no-repeat padding-box;
  border-radius: 0px 16px;
  width: 21vw;
  padding: 1vw;
  opacity: 1;
  text-align:center;
  display: flex;
  margin-right: 2vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1080px) {
   width: 70vw;
   margin-bottom:2vh;
  }
`;

export const CEOCard = styled.div`
  background: #ff8811 0% 0% no-repeat padding-box;
  border-radius: 0px 16px;
  width: 18vw;
  margin-top: 20vh;
  padding: 1.5vw;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1080px) {
    width: 70vw;
    margin: 2vh 0;
   }
`;

export const FounderImg = styled.img`
width:50%;
margin-bottom:1vh;
`;

export const FounderDesignation = styled.p`
  font: normal normal bold 26px/32px Josefin Sans;
  letter-spacing: 0px;
  color: #fff4f0;
  @media screen and (max-width: 1080px) {
    font: normal normal bold 26px/28px Josefin Sans;
   }
`;

export const FounderName = styled.p`
  font: normal normal bold 26px/32px Josefin Sans;
  letter-spacing: 0px;
  color: #fff4f0;
  text-align:center;
  @media screen and (max-width: 1080px) {
    font: normal normal bold 22px/28px Josefin Sans;
   }
`;

export const AboutFounder = styled.p`
  font: normal normal normal 26px/28px Josefin Sans;
  letter-spacing: 0px;
  margin:2vh 0;
  text-align:center;
  color: #fff4f0;
  @media screen and (max-width: 1080px) {
    font: normal normal normal 20px/28px Josefin Sans;
   }
`;

export const FounderSummary = styled.p`
  font: normal normal bold 26px/28px Josefin Sans;
  letter-spacing: 0px;
  text-align:center;
  color: #fff4f0;
  @media screen and (max-width: 1080px) {
    font: normal normal bold 20px/28px Josefin Sans;
   }
`;

export const PurposeCard = styled.div`
  background-image: url(${PurposeBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 5vw;
  justify-content: center;
  width: 50vw;
  height: 42vh;
  margin: 0 auto;
  @media screen and (max-width: 1080px) {
    padding: 6vw;
    padding-top: 5vh;
    width: 65vw;
    height: 30vh;
    text-align:center;
    background-size: 100% 100%;
   }
`;

export const PurposeWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5vw;
  @media screen and (max-width: 1080px) {
    flex-direction: column;
   }
`;

export const PurposeTextWrapper = styled.div`
  font-weight:bold;
  margin-right:5vw;
  @media screen and (max-width: 1080px) {
    margin-right:0;
   }
`;

export const PurposeHeading = styled.h1`
  font: normal normal 600 50px/65px Josefin Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal 600 28px/32px Josefin Sans;
   }
`;

export const PurposeText = styled.p`
  font: normal normal 600 26px/38px Josefin Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  @media screen and (max-width: 1080px) {
    font: normal normal normal 20px/24px Josefin Sans;
   }
`;

export const GameIconImg = styled.img`
  height: 30vh;
  object-fit: cover;
  @media screen and (max-width: 1080px) {
    width: 0;
    height: 0;
   }
`;

export const ReadMoreDiv = styled.div`
margin-left:0vw;
@media screen and (max-width: 1080px) {
    margin:auto;
}
`;